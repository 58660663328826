<template>
  <v-row justify="center">

    <v-col cols="12" xs="12" sm="12" md="6" lg="4">
      <CardOpcionMenu
          to="/informacion/acerca-de"
          titulo="Acerca del conservador"
          icono="mdi-information"
          :has-body="false"
      >
      </CardOpcionMenu>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="6" lg="4">
      <CardOpcionMenu
          to="/informacion/tyc"
          titulo="Terminos y condiciones"
          icono="mdi-file-document-edit"
          :has-body="false"
      >
      </CardOpcionMenu>
    </v-col>

    <v-col cols="12" xs="12" sm="12" md="6" lg="4">
      <CardOpcionMenu
          to="/informacion/faq"
          titulo="Preguntas frecuentes"
          icono="mdi-help-circle"
          :has-body="false"
      >
      </CardOpcionMenu>
    </v-col>

    <!--
    <v-col cols="12" xs="12" sm="12" md="6" lg="4">
      <CardOpcionMenu
          to="/informacion/trabaja-con-nosotros"
          titulo="Trabaja con nosotros"
          icono="mdi-account-hard-hat"
          :has-body="false"
      >
      </CardOpcionMenu>
    </v-col>
    -->

    <v-col cols="12" xs="12" sm="12" md="6" lg="4">
      <CardOpcionMenu
          to="/informacion/contacto"
          titulo="Contacto"
          icono="mdi-card-account-mail"
          :has-body="false"
      >
      </CardOpcionMenu>
    </v-col>


  </v-row>

</template>

<script>
import CardOpcionMenu from "@/components/CardOpcionMenu/CardOpcionMenu";

export default {
  name: "InformacionViewMenu",
  components: {CardOpcionMenu},
}
</script>

<style scoped>

</style>